<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        员工轨迹表
      </div>
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="选择人员">
            <el-select size="small" v-model="formInline.staffId" clearable placeholder="选择人员">
              <el-option
                v-for="item in yuangong"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择日期">
            <el-date-picker
              :picker-options="pickerOptions"
              v-model="formInline.date"
              style="width: 215px"
              value-format="timestamp"
              type="date"
              size="small"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="alias" label="员工姓名"></el-table-column>
          <el-table-column prop="facilityName" label="所属部门"></el-table-column>
          <el-table-column prop="patrolName" label="里程段数"></el-table-column>
          <el-table-column prop="deviceTypeName" label="所在城市"></el-table-column>
          <el-table-column prop="departmentName" label="轨迹时间">
            <template slot-scope="scope">
              {{ dateSecond(scope.row.startTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="departmentName" label="轨迹地址"></el-table-column>
          <el-table-column prop="startDate" label="日期">
            <template slot-scope="scope">
              {{ scope.row.startDate ? dayjs(scope.row.startDate).format('YYYY-MM-DD'):'-' }}
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs'

export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [],
      isChangeOpen: 0,
      bumen: [],
      filters: [
        {
            id: 1,           
            name: '按人员'
        },
        {
            id: 2,
            name: '按日期'
        }
      ],
      sheshi: [],
      yuangong: [],
      isDetailOpen: 0,
      detailRow: {},
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    };
  },
  methods: {
    dayjs,
    dateSecond(val) {
      if (val) {
        let s = val % 60
        let _s = val - s

        let m = (_s / 60) % 60

        let h = (_s / 60 - m) / 60

        return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
      } else {
        return '00:00:00'
      }
    },
    details(row) {
      // this.$router.push({ name: 'deviceScrapDetail', params: {row: row}})
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadListData()
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    loadListData() {
      this.loading = true
      this.$ajax.post('scrapDevice', {
          departmentId: this.formInline.departmentId?this.formInline.departmentId:null,
          deviceTypeId: this.formInline.deviceTypeId?this.formInline.deviceTypeId:null,
          facilityId: this.formInline.facilityId?this.formInline.facilityId:null,
          staffId: this.formInline.staffId?this.formInline.staffId:null
      },{
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    load_bumen() {
      this.$ajax.get("departmentTree").then((res) => {
        this.bumen = this.getTreeData(res.data);
      });
    },
    load_leixing() {
      this.$ajax.get("typeQueryAll").then((res) => {
        this.leixin = res.data;
      });
    },
    load_sheshi() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.sheshi = res.data;
      });
    },
    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    getLastPage() {
      if(sessionStorage.getItem('detail')) {
        this.page = Number(sessionStorage.getItem('currentPage'))
      }else {
        this.page = 1
        sessionStorage.removeItem('currentPage')
      }
    }
  },
  mounted() {
    this.getLastPage()
    this.load_bumen();
    this.load_leixing();
    this.load_sheshi();
    this.load_yuangong();
    this.loadListData()
  },
  destroyed() {
    sessionStorage.removeItem('detail')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>